export const isMobileOrIpadService = () => {
    // Define a breakpoint for mobile and iPad devices
    const mobileOrIpadBreakpoint = 768; // Adjust as needed
    return window.innerWidth <= mobileOrIpadBreakpoint;
};
export const isAppleDevice = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platform = window.navigator.platform.toLowerCase();
    // Check if it's an iOS device
    if (/iphone|ipod|ipad/.test(userAgent) || (userAgent.includes('macintosh') && 'ontouchend' in document)) {
        return true;
    }
    // Check if it's a MacBook and the browser is Safari
    const isMacBook = userAgent.includes('macintosh') && platform === 'macintel';
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isMacBook && isSafari) {
        return true;
    }
    // Check if it's an iPad
    if (userAgent.includes('ipad') || (platform === 'macintel' && navigator.maxTouchPoints > 1)) {
        return true;
    }
    // If none of the conditions are met
    return false;
};
export const isDarkColor = (bgColor) => {
    if (!bgColor) {
        return false;
    }
    // Remove '#' if present
    bgColor = bgColor.replace('#', '');
    // Parse the hex color
    const r = parseInt(bgColor.substring(0, 2), 16);
    const g = parseInt(bgColor.substring(2, 4), 16);
    const b = parseInt(bgColor.substring(4, 6), 16);
    // Calculate luminance
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    // Return black for light backgrounds and white for dark backgrounds
    return luminance > 186;
};
