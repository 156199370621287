import React from "react";
import { createRoot } from "react-dom/client";
// `Component` should be a React component that expects `dataset` as a prop
export const reactMount = (ComponentName, Component) => {
    document.addEventListener('DOMContentLoaded', () => {
        // Use querySelectorAll and type it as a NodeListOf<Element> for proper DOM element typing
        const elements = document.querySelectorAll(`[data-react-mount='${ComponentName}']`);
        elements.forEach((element) => {
            var _a;
            const dataset = (_a = element === null || element === void 0 ? void 0 : element.dataset) !== null && _a !== void 0 ? _a : null;
            const root = createRoot(element);
            root.render(React.createElement(Component, { dataset }));
        });
    });
};
