import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from '../styles/chip.module.scss';
import Fox from './gameIcons/fox';
import Girls from './gameIcons/girls';
import KiwiTag from './gameIcons/kiwiTag';
import ArlTag from './gameIcons/arlTag';
import LeagueBall from './gameIcons/leagues';
import OtherCharacteristics from './gameIcons/otherCharacteristics';
import PremierReserves from './gameIcons/premierReserves';
import Sharman from './gameIcons/sharman';
import SteeleShanks from './gameIcons/steeleShanks';
import WeightRestricted from './gameIcons/weightRestricted';
import Woman from './gameIcons/woman';
const tags = {
    league: _jsx(LeagueBall, { type: 'clubs' }),
    fox: _jsx(Fox, { type: 'clubs' }),
    girls: _jsx(Girls, { type: 'clubs' }),
    kiwiTag: _jsx(KiwiTag, { type: 'clubs' }),
    'kiwi-tag': _jsx(KiwiTag, { type: 'clubs' }),
    arlTag: _jsx(ArlTag, { type: 'clubs' }),
    'arl-tag': _jsx(ArlTag, { type: 'clubs' }),
    sharman: _jsx(Sharman, { type: 'clubs' }),
    steeleShanks: _jsx(SteeleShanks, { type: 'clubs' }),
    steeleshanks: _jsx(SteeleShanks, { type: 'clubs' }),
    weightRestricted: _jsx(WeightRestricted, { type: 'clubs' }),
    woman: _jsx(Woman, { type: 'clubs' }),
    'women-prems': _jsx(Woman, { type: 'clubs' }),
    weight: _jsx(WeightRestricted, { type: 'clubs' }),
    'heritage-(75+-years)': _jsx(OtherCharacteristics, {}),
    'highly-competitive': _jsx(OtherCharacteristics, {}),
    'social-club': _jsx(OtherCharacteristics, {}),
    'whanau-focus': _jsx(OtherCharacteristics, {}),
    'premierereserves': _jsx(PremierReserves, { type: 'clubs' })
};
const Chip = ({ title, label, isTags, icons, iconText, isClickable, handleClick, isContrast, isWhite }) => {
    return (isTags
        ? _jsx("div", { className: `${styles.chipTags} ${isContrast ? styles.isContrast : ''} `, children: _jsxs("div", { children: [iconText ? _jsx("span", { children: iconText }) : icons ?
                        icons === null || icons === void 0 ? void 0 : icons.split(',').map((icon) => tags[icon]) : '', title, isClickable &&
                        _jsx("div", { className: styles.chipTagsClose, onClick: handleClick, children: _jsx("svg", { className: styles.spriteIcon, children: _jsx("use", { xlinkHref: '#i-close' }) }) })] }) })
        : _jsxs("div", { className: `${styles.chipButton} ${isWhite ? styles.white : ''}`, children: [_jsx("div", { children: title }), label &&
                    _jsx("span", { children: label })] }));
};
export default Chip;
