import { formatDistanceToNow, format } from 'date-fns';
export const timeAgo = (date) => {
    let timeAgo = '';
    if (date) {
        const formatedDate = new Date(date);
        const timePeriod = formatDistanceToNow(formatedDate);
        timeAgo = `${timePeriod} ago`;
    }
    return timeAgo;
};
// formate date - 12th December 2021 add th, st, nd, rd
// formate date - Nov 29, 2023 type = short
// formate date - 29th November 2023 11:30 AM
export const formatDate = (date, type) => {
    let formatedDateString = '';
    if (date) {
        const formatedDate = new Date(date);
        const day = formatedDate.getDate();
        const month = formatedDate.toLocaleString('default', { month: 'long' });
        const year = formatedDate.getFullYear();
        if (type === 'short') {
            formatedDateString = `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
        }
        else if (type === 'time') {
            const parsedDate = new Date(date);
            formatedDateString = format(parsedDate, 'do MMMM yyyy hh:mm a');
        }
        else {
            formatedDateString = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
        }
    }
    return formatedDateString;
};
// add th, st, nd, rd to the date
const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21)
        return 'th';
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};
export const getPlaceholderImage = (type) => {
    if (type == 'News') {
        return '../../../resources/images/new/background/news-placeholder.png';
    }
    else if (type == 'Match Report') {
        return '../../../resources/images/new/background/match-report-placeholder.png';
    }
    else if (type == 'Event') {
        return '../../../resources/images/new/background/event-placeholder.png';
    }
    return '../../../resources/images/new/background/article-placeholder.png';
};
