import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import '../styles/main.scss';
import Header from './header';
import Home from './homePage';
import { reactMount } from '../../ClientApp/lib/react-mount';
const headerElement = document.getElementById('header');
if (headerElement != null) {
    const rootHeader = createRoot(headerElement);
    rootHeader.render(_jsx(Header, {}));
}
reactMount('homePage', Home);
