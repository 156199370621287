import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Icons from './icons';
import styles from '../styles/Home.module.scss';
import Testimonials from './testimonials';
import HomeChampionships from './homeChampionships';
import ContactCTA from './contactCTA';
import Header from './header';
import Chip from './chip';
import Footer from './footer';
import NewsBannerSwiper from './newsBannerSwiper';
import { useEffect, useRef, useState } from 'react';
import Mareuee from './marquee';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LazyImage from './lazyImage';
import { isAppleDevice } from '../../ClientApp/services/ResponsiveService';
import VideoPlayer from './videoPlayer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
export default function Home({ dataset }) {
    const umbracoRef = useRef(JSON.parse(dataset.umbraco));
    const umbraco = umbracoRef.current;
    const articleListJson = document.getElementById('featuredarticlesJson');
    let pageTitle = umbraco.headerTitle;
    pageTitle = pageTitle ? pageTitle === null || pageTitle === void 0 ? void 0 : pageTitle.split(';') : [];
    const [featuredArticles, setFeaturedArticles] = useState(null);
    useEffect(() => {
        setTimeout(() => {
            AOS.init({
                // disable: 'mobile',
                startEvent: 'DOMContentLoaded',
                delay: 300,
                duration: 500,
                easing: 'ease'
            });
        }, 2000);
        let featuredArticles = articleListJson.value ? JSON.parse(articleListJson.value).filter((res) => res.IsFeaturedArticle) : [];
        featuredArticles = sortByDate(featuredArticles);
        setFeaturedArticles(featuredArticles);
    }, []);
    const getSecondaryButtonText = (text) => {
        let textList = (text === null || text === void 0 ? void 0 : text.split(':')) || [];
        let result = {};
        if (textList.length > 1) {
            result = { text: textList[0], subText: textList[1] };
        }
        else {
            result = { text: textList[0], subText: '' };
        }
        return result;
    };
    let secondaryText = getSecondaryButtonText(umbraco === null || umbraco === void 0 ? void 0 : umbraco.secondaryButtonText);
    const sortByDate = (articleData) => {
        const sortedData = articleData.sort((a, b) => {
            const dateA = new Date(a.Date).getTime();
            const dateB = new Date(b.Date).getTime();
            return dateB - dateA;
        });
        return sortedData;
    };
    const getWhatLeague = () => {
        return umbraco.AboutArlCards.map((item, index) => {
            var _a;
            return (_jsx("div", { className: styles.homeTileContainer, children: _jsxs("div", { className: styles.homeTileContainerImage, children: [_jsx(LazyImage, { className: styles.homeTileImageDesktop, src: item.Image, alt: 'Women League Rugby' }), _jsx("div", { className: `${styles['homeTileContainer--homeTileBodyContainer']} ${index == 1 ? styles['homeTileContainer--homeTileBodyContainer--middle'] : ''}`, children: _jsxs("div", { className: styles['homeTileContainer--homeTileBody'], children: [_jsx("div", { children: _jsx(Chip, { title: item.Tag }) }), _jsxs("div", { children: [_jsx("h2", { className: styles['homeTileContainer--title'], children: item.Title }), _jsx("p", { className: styles['homeTileContainer--desc'], children: item.SubTitle }), ((_a = item === null || item === void 0 ? void 0 : item.Button) === null || _a === void 0 ? void 0 : _a.Name) &&
                                                _jsx("a", { href: item.Button.Url, target: item.Button.Target, className: `${styles.button} ${styles['button--new1']}`, children: item.Button.Name })] })] }) })] }) }));
        });
    };
    useEffect(() => {
        var _a, _b;
        const copy = (_a = document.querySelector('#imagesSlider')) === null || _a === void 0 ? void 0 : _a.cloneNode(true);
        if (copy != null) {
            (_b = document.querySelector('#imageSlider')) === null || _b === void 0 ? void 0 : _b.appendChild(copy);
        }
    }, []);
    const scrollDown = () => {
        var whyplayleague = document.getElementById('WhyplayleagueSection');
        let top = (whyplayleague === null || whyplayleague === void 0 ? void 0 : whyplayleague.offsetTop) || 0;
        window.scrollTo({ top: top - 40, behavior: 'smooth' });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx("style", { children: `
        .heroCarousel {
          pointer-events: none;
          &.swiper{
            height: 100%;
            max-height: 350px;

            @media (max-width: 774px) {
              max-height: 200px;
            }
          }
          .swiper-slide {
            width: auto;
            max-height: 300px;
            width: 550px;
            @media (max-width: 774px) {
              max-height: 200px;
              width: 300px;
            }
          }

          >.swiper-wrapper{
              height: 300px;
              width: 550px;
              transition-timing-function : linear;
              @media (max-width: 774px) {
                height: 200px;
                width: 300px;
              }
          }
        }
        
            .swiper-pagination-bullet {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0);
              opacity: 1;
              width: 3rem;
              border-radius: 0.25rem;
              height: 3rem;
              border: 0;
            }
            
            .swiper-pagination-bullet:before {
              content: '';
              display: block;
              width: 2rem;
              height: .5rem;
              background-color: white;
              border-radius: 0.25rem;
            }
            .swiper-pagination-bullet-active:before {
              background-color: var(--button-2-brand);
            }
          
            .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
              bottom: 0rem;
            }
            .swiper-slide {
              height: auto;
            }
          ` }), _jsx(Icons, {}), _jsx("section", { className: `  ${styles.section} ${styles.hero} ${styles.headerBackground} `, children: _jsxs("div", { className: styles.heroInner, children: [_jsx("div", { className: styles.backgroundVideoContainer, children: _jsx(VideoPlayer, { src: isAppleDevice()
                                    ? '/resources/videos/hevc/arl-ball-landing-hevc.mov'
                                    : '/resources/videos/webm/arl-ball-landing.webm', poster: '/resources/images/new/background/arl-ball-landing.png', type: isAppleDevice() ? 'video/quicktime' : 'video/webm' }) }), _jsxs("div", { className: `${styles.mainContainer} ${styles.heroTitleOrder}`, children: [_jsxs("div", { className: styles.heroContentCol, children: [_jsx("div", { className: styles.heroTitleContainer, children: pageTitle === null || pageTitle === void 0 ? void 0 : pageTitle.map((title, index) => {
                                                return _jsx("h1", { className: styles.heroTitle, children: title }, index);
                                            }) }), _jsxs("div", { className: styles.heroActions, children: [umbraco.primaryButtonText &&
                                                    _jsx("div", { className: styles.heroAction, children: _jsx("a", { href: umbraco.primaryButtonLink || '#', target: umbraco.primaryButtonLinkTarget || '_self', className: `${styles.buttonPrimary} ${styles.buttonPrimaryMedium}`, children: umbraco.primaryButtonText }) }), secondaryText.text &&
                                                    _jsx("div", { className: styles.heroAction, children: _jsxs("a", { target: umbraco.secondaryButtonLinkTarget || '_self', href: umbraco.secondaryButtonLink || '#', className: `${styles.button} ${styles['button--new2']} ${styles['button--larger-at-mobile']}`, children: [secondaryText.text, secondaryText.subText &&
                                                                    _jsx("span", { children: secondaryText.subText })] }) })] })] }), _jsx("div", { children: _jsxs("span", { className: styles.heroSliderPagination, children: ["SCROLL DOWN", _jsx("svg", { className: styles.spriteIcon, children: _jsx("use", { xlinkHref: '#i-arrow-down' }) })] }) })] }), _jsxs("div", { className: `${styles.heroSlider} ${styles.mainContainer}`, children: [_jsx(Swiper, { className: `heroCarousel`, spaceBetween: 10, speed: 14000, slidesPerView: 'auto', centeredSlides: false, loop: true, scrollbar: { draggable: true }, autoplay: {
                                        delay: 0,
                                        pauseOnMouseEnter: false
                                    }, modules: [Autoplay], freeMode: true, draggable: false, preventClicks: true, preventClicksPropagation: true, children: [...umbraco.Carousel, ...umbraco.Carousel].map((x, index) => {
                                        return (_jsx(SwiperSlide, { children: _jsx("div", { className: styles.swiperSlideContainer, children: _jsx(LazyImage, { src: x, alt: '', lazy: false }) }) }, index));
                                    }) }), _jsx("div", { children: _jsxs("span", { className: styles.heroSliderPagination, onClick: scrollDown, children: ["SCROLL DOWN", _jsx("svg", { className: styles.spriteIcon, children: _jsx("use", { xlinkHref: '#i-arrow-down' }) })] }) })] }), _jsx("div", { className: styles.backgroundHeaderMobileContainer, children: _jsx("div", {}) })] }) }), _jsx(Mareuee, { content: 'Greatness Grows here', isRed: false, isReverse: true }), _jsxs("section", { id: 'WhyplayleagueSection', className: styles.homeTiles, children: [_jsx("div", { className: `${styles.homeTilesWrapper} ${styles.homeTilesWrapperMobile}` }), _jsx("div", { className: styles.mainContainer, children: _jsxs("div", { "data-aos": 'fade-up', className: styles.homeTilesWrapper, children: [_jsxs("div", { className: `${styles.homeTilesTitle} ${styles['homeTilesTitle--order1']}`, children: [_jsx(Chip, { title: 'About ARL' }), _jsx("h2", { className: styles['homeTilesTitle--title'], children: umbraco.AboutArlTitle })] }), _jsx("div", { className: styles.homeTilesContainer, children: getWhatLeague() }), _jsxs("div", { className: `${styles.homeTilesTitle} ${styles['homeTilesTitle--order3']}`, children: [_jsx("h3", { className: styles['homeTilesTitle--surveyTitle'], children: umbraco.FeedbackTitle }), _jsx("p", { className: styles['homeTilesTitle--surveyDesc'], children: umbraco.CallToAction }), _jsx("a", { href: umbraco.FeedbackLink.Url, target: umbraco.FeedbackLink.Target, className: `${styles.buttonPrimary} ${styles.buttonPrimaryMedium} ${styles['homeTilesTitle--surveybtn']}`, children: umbraco.FeedbackLink.Name })] })] }) })] }), _jsxs("div", { className: styles.testimonials, children: [_jsx("div", { className: `${styles.testimonialsGradients} ${styles.testimonialsGradientsLg}` }), _jsxs("div", { className: styles.testimonialsContainer, children: [_jsx("div", { className: styles.testimonialsContainerVideo, children: _jsx(VideoPlayer, { src: isAppleDevice()
                                        ? '/resources/videos/hevc/arl-ball-zoom-in-hevc.mov'
                                        : '/resources/videos/webm/arl-ball-zoom-in.webm', poster: '/resources/images/new/background/arl-ball-zoom-in.png', type: isAppleDevice() ? 'video/quicktime' : 'video/webm' }) }), _jsx("div", { className: `${styles.testimonialsGradients} ${styles.testimonialsGradientsSm}` }), _jsx("div", { "data-aos": 'fade-up', className: styles.mainContainerMd, children: _jsx(Testimonials, { testimonial: umbraco.testimonials }) })] })] }), umbraco.featuredPlayBlock.length > 0 &&
                umbraco.featuredPlayBlock.map((featuredItem, index) => {
                    return (_jsx("section", { className: `${styles.premiership} ${index === (umbraco.featuredPlayBlock.length - 1) ? styles['premiership--nonContact'] : ''}`, children: _jsxs("div", { "data-aos": 'fade-up', className: styles.mainContainer, children: [_jsx("div", { className: styles.premiershipTitle, children: _jsx("div", { className: styles['premiershipTitle--title'], children: featuredItem.Title }) }), _jsx("div", { className: styles.premiershipTilesContainer, children: featuredItem.FeaturedPlayCards.map((item, index) => {
                                        var _a, _b, _c, _d;
                                        return (_jsx(_Fragment, { children: _jsxs("div", { className: styles.premiershipTileContainer, children: [_jsx("div", { className: styles.premiershipTileContainerImage, children: _jsx(LazyImage, { src: item.Image, alt: item.Title, className: styles.homeTileImageDesktop }) }), _jsx("div", { className: styles.premiershipTileContainerGradient }), _jsx("div", { className: styles['premiershipTileContainer--BodyContainer'], children: _jsxs("div", { className: styles['premiershipTileContainer--Body'], children: [_jsxs("div", { className: styles['premiershipTileContainer--Header'], children: [((_a = item.LeagueTags) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                                                                            _jsx(Chip, { title: (_b = item.LeagueTags) === null || _b === void 0 ? void 0 : _b[0], label: ((_c = item.LeagueTags) === null || _c === void 0 ? void 0 : _c[1]) || '' }), _jsx("div", { children: item.IconOrText.map((icon) => {
                                                                                if (icon.Icon) {
                                                                                    return (_jsx("img", { src: icon.Icon }));
                                                                                }
                                                                            }) }), item.IconOrText.map((icon) => {
                                                                            if (icon.Title) {
                                                                                return _jsxs("span", { children: [" ", icon.Title, " "] });
                                                                            }
                                                                        })] }), _jsxs("div", { children: [_jsx("h2", { className: styles['premiershipTileContainer--title'], children: item.Title }), _jsxs("div", { className: styles['premiershipTileContainer--Footer'], children: [_jsxs("div", { children: [_jsx("p", { className: styles['premiershipTileContainer--desc'], children: item.Description }), _jsx("div", { className: styles.infoItems, children: (_d = item === null || item === void 0 ? void 0 : item.PlayType) === null || _d === void 0 ? void 0 : _d.map((game) => {
                                                                                                return (_jsxs("div", { className: styles.infoItem, children: [_jsx("img", { src: game.Icon, className: styles.infoItemIcon }), _jsx("span", { className: styles.infoItemText, children: game.Title })] }));
                                                                                            }) })] }), item.Button.Name &&
                                                                                    _jsx("a", { href: item.Button.Url, target: item.Button.Target, className: `${styles.buttonPrimary}`, children: item.Button.Name })] })] })] }) })] }) }));
                                    }) })] }) }));
                }), _jsx("section", { className: styles.newsBannerHome, children: _jsxs("div", { "data-aos": 'fade-up', children: [_jsxs("div", { className: styles.newsBannerHomeHeader, children: [_jsx("h2", { children: "What's new?" }), _jsx("span", { className: styles.newsBannerHomeHeaderSubTitle, children: "Get with the latest news & events!" }), _jsx("a", { href: '/news', className: `${styles.buttonSecondary} ${styles.buttonSecondaryMedium}`, children: "NEWS & EVENTS" })] }), _jsx(NewsBannerSwiper, { featuredArticlesJson: featuredArticles, isHomePage: true })] }) }), _jsx(Mareuee, { content: 'Come join the game', isRed: true, isReverse: false }), _jsx("section", { className: styles.whatToPlay, children: _jsx("div", { "data-aos": 'fade-up', children: _jsxs("div", { className: styles['whatToPlay--container'], children: [_jsxs("div", { className: styles['whatToPlay--wrapper'], children: [_jsx("div", { className: styles['whatToPlay--wrapper--title'], children: "Not sure what to play?" }), _jsx("div", { className: styles['whatToPlay--wrapper--desc'], children: "Have a hui with one of our advisors to find the options that will better suit you." }), _jsx("div", { className: styles['whatToPlay--wrapper--button'], children: _jsxs("a", { href: '/clubs/', className: `${styles.button} ${styles['button--new2']}`, children: ["Find out more", _jsx("svg", { className: styles.spriteIcon, children: _jsx("use", { xlinkHref: '#i-arrow-down' }) })] }) })] }), _jsx("div", { className: styles['whatToPlay--video'], children: _jsx(VideoPlayer, { src: isAppleDevice()
                                        ? '/resources/videos/hevc/arl-ball-out-red-hevc.mov'
                                        : '/resources/videos/webm/arl-ball-out-red.webm', poster: '/resources/images/new/background/arl-ball-out-red.png', type: isAppleDevice() ? 'video/quicktime' : 'video/webm' }) })] }) }) }), _jsx(Mareuee, { content: 'Come join the game', isRed: true, isReverse: true }), _jsx(HomeChampionships, {}), _jsx(Mareuee, { content: 'Support your team', isRed: false, isReverse: false }), _jsx(ContactCTA, {}), _jsx(Footer, { headerFooter: false })] }));
}
